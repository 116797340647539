// @import '~antd/dist/antd.less';

.warehouse-card {
  border-radius: @border-radius-base;
  background-color: @primary-1;
  padding: 16px !important;
  cursor: pointer;

  &:hover {
    background-color: @primary-2;
  }
  
  &.active {
    background-color: @primary-3;
  }
  
}