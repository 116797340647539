
.category-item {

  .category-item-icon {
    border: 2px solid transparent;
  }
  
  // .category-item-label { }
  
  &.active {
    .category-item-icon {
      border: 2px solid @primary-color;
    }
    
    .category-item-label {
      color: @primary-color;
    }
  }

  :hover {
    .category-item-icon {
      border: 2px solid @primary-5;
    }
    
    .category-item-label {
      color: @primary-5;
    }
  }

}
