.title-container {
  margin-bottom: 1em;
}

.title-line-height {
  line-height: .5em;
}

.title {
  margin: 0 !important;
}