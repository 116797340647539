.w-full {
  width: 100% !important;
}

.m-0 {
  margin: 0px !important;
}

.m-1 {
  margin: 8px !important;
}

.m-2 {
  margin: 16px !important;
}

.m-3 {
  margin: 24px !important;
}

.m-4 {
  margin: 32px !important;
}

.mb-4 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 8px !important;
}

.mb-2 {
  margin-bottom: 16px !important;
}

.mb-3 {
  margin-bottom: 24px !important;
}

.mb-4 {
  margin-bottom: 32px !important;
}

.mr-1 {
  margin-right: 8px !important;
}

.mt-2 {
  margin-top: 16px !important;
}

.my-16 {
  margin: 16px 0;
}

.mx-10 {
  margin: 0 10px;
}

.p-0 {
  padding: 0px;
  }

.p-1 {
  padding: 8px;
  }

.p-2 {
padding: 16px;
}

.p-3 {
  padding: 24px;
  }

.p-4 {
padding: 32px;
}

.pt-1 {
  padding-top: 8px;
  }

.pv-1 {
  padding: 8px 0px;
  }

.br-1 {
  border-radius: 5px;
}

.bg-gray-200 {
  background-color: #f5f5f5;
}

.bg-blue-200 {
  background-color: #bae7ff;
}

.text-center {
  text-align: center !important;
}

.mask-image-text {
  mask-image: linear-gradient(to top,transparent,currentColor 20px);
  -webkit-mask-image: linear-gradient(to top,transparent,currentColor 20px);
}

.text-white {
  color: white;
}

.icon-large {
  font-size: 1.25rem;
}
